import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import Konferenz from './Konferenz';
import Logger from './lib/Logger';
import UrlParse from 'url-parse';
import domready from 'domready'
import randomString from 'random-string';
import { Provider as ReduxProvider } from 'react-redux';
import { createStore } from './redux/store';
import debug from 'debug';
import i18nextInit from './i18n';
import RoomClient, {VIDEO_CONSTRAINTS} from './lib/RoomClient';
import RoomContext from './lib/RoomContext';
import { setMe, setRoomUrl } from './redux/actions';

i18nextInit();

debug.enable('Konferenz:*');
// debug.enable('*');
const log = new Logger();

const reduxStore = createStore();

interface Codec {
  codec: string;
  encodings: Array<any>;
};
const codecs: Map<string, Codec> = new Map([
  ['vp8', {
    codec: 'video/vp8',
    encodings: [
      { scaleResolutionDownBy: 4, maxBitrate: 250000 },
      { scaleResolutionDownBy: 2, maxBitrate: 1000000 },
      { scaleResolutionDownBy: 1, maxBitrate: 2000000 }
    ]
  }],
  ['vp8-hires', {
    codec: 'video/vp8',
    encodings: [
      { scaleResolutionDownBy: 1, maxBitrate: 16000000 }
    ]
  }],
  ['vp9', {
    codec: 'video/vp9',
    encodings: [
      { scalabilityMode: 'S3T3_KEY' }
    ]
  }],
  ['h264', {
    codec: 'video/h264',
    encodings: [
      { scaleResolutionDownBy: 1, maxBitrate: 16000000 }
    ]
  }]
]);
const defaultCodec = 'vp8';

domready(async () => {
  log.debug('DOM ready.');

  const urlParser = new UrlParse(window.location.href, true);
  let roomId = urlParser.pathname.substr(1);
  const peerId = randomString({ length: 16 }).toLowerCase();
  const displayName = localStorage.getItem('displayName');

  let codec: Codec | undefined = undefined;
  if (urlParser.query['codec'] && codecs.has(urlParser.query['codec'])) {
    codec = codecs.get(urlParser.query['codec']);
  } else {
    codec = codecs.get(defaultCodec);
  }
  let resolution = 'fhd';
  if (urlParser.query['resolution'] && VIDEO_CONSTRAINTS.has(urlParser.query['resolution'])) {
    resolution = urlParser.query['resolution'];
  }

  let produce = true;
  if (urlParser.query['consumeOnly']) {
    produce = false;
  }

  if (!roomId) {
    roomId = randomString({ length: 8 });
    urlParser.set('pathname', `/${roomId}`);
    window.history.pushState(null, '', urlParser.toString());
  }

  const roomUrl = urlParser.toString();

  reduxStore.dispatch(setRoomUrl(roomUrl));
  reduxStore.dispatch(setMe({
    id: peerId,
    displayName: displayName
  }));

  if (produce) {
    try {
      navigator.mediaDevices.getUserMedia({
        audio: true
      })
      .then((stream) => {
        console.log('ok')
        stream.getTracks().forEach(track => track.stop());
      })
      .catch(e => console.log(e));

    } catch (e) {
      console.log(e);
    }
  }

  const roomClient = new RoomClient({
    reduxStore,
    roomId,
    peerId,
    produce,
    consume: true,
    preferredCodec: codec.codec,
    preferredResolution: resolution,
    encodings: codec.encodings
  });

  (window as any).CLIENT = roomClient;

  ReactDOM.render(
    <React.StrictMode>
      <ReduxProvider store={reduxStore}>
        <RoomContext.Provider value={roomClient}>
          <Konferenz />
        </RoomContext.Provider>
      </ReduxProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
});
